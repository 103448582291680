import { render, staticRenderFns } from "./PlansHeader.vue?vue&type=template&id=4a192b2a&scoped=true"
import script from "./PlansHeader.vue?vue&type=script&lang=js"
export * from "./PlansHeader.vue?vue&type=script&lang=js"
import style0 from "./PlansHeader.vue?vue&type=style&index=0&id=4a192b2a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a192b2a",
  null
  
)

export default component.exports