<template>
  <v-card class="plan-card" :class="currentLanguage" elevation="0">
    <v-card-title class="card__title pa-0">
      <img
        alt="digifarmz-leaf"
        class="title__icon"
        max-height="30px"
        src="icons/ic-leaf.svg"
      />
      <v-divider vertical class="title__divider" />
      <v-chip :class="plan.type" class="title__plan-type">
        <p>{{ plan.type }}</p>
      </v-chip>
      <v-spacer />
      <v-chip v-if="plan.client" class="title__plan-type client pro">
        <p>{{ $t(`Plans.` + plan.client) }}</p>
      </v-chip>
      <v-spacer />
    </v-card-title>
    <v-card-subtitle class="card__subtitle mt-3 pa-0">
      <p v-html="formattedText(plan.description)"></p>
    </v-card-subtitle>

    <v-card-text class="my-8 pa-0">
      <div class="d-flex flex-column justify-center text__prices">
        <p class="text__main-price">
          <span>
            {{ formatPrice(plan.price.month) }}
          </span>
          <span class="price-units">
            {{ $t('Plans.per_month') }}
          </span>
        </p>
        <p v-if="plan.price.monthlyHectare" class="text__secondary-price">
          <span> + {{ formatPrice(plan.price.monthlyHectare) }} </span>
          <span v-if="plan.price.monthlyHectare" class="price-units">
            {{ $t('Plans.per_month_per_hectare') }}
          </span>
        </p>
      </div>
      <div
        v-for="feature in plan.features"
        class="plan-card__features"
        :key="feature.id"
      >
        <plan-card-feature :feature="feature" />
      </div>
      <div class="observation">
        <p
          v-for="observation in plan.observations"
          :key="observation"
          class="my-1"
        >
          * {{ observation }}
        </p>
      </div>
    </v-card-text>
    <v-card-actions class="plan-card__actions px-0">
      <df-button
        v-if="isContracted"
        variant="secondary"
        icon="check"
        color-icon="#39AF49"
      >
        {{ $t('Plans.have_this_plan') }}
      </df-button>
      <df-button v-else @click="talkSeller">
        {{ $t('Plans.request_plan') }}
      </df-button>
    </v-card-actions>
    <plan-payment-choices-modal
      v-if="isPaymentModalOpened"
      :plan-priority="plan.priority_level"
      @close="closeModal"
    />
  </v-card>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import PlanCardFeature from './PlanCardFeature.vue'
import PlanPaymentChoicesModal from '@/modules/payment/components/modal/PlanPaymentChoicesModal.vue'
import PlanMixin from '@/components/PlanMixin.vue'
import planLevels from '@/modules/plans/data/planLevels'
import { mapGetters } from 'vuex'
import { logEvent, events } from '@/services/analytics'
import { currentLanguage } from '@/language'

export default {
  mixins: [PlanMixin],

  components: {
    DfButton,
    PlanCardFeature,
    PlanPaymentChoicesModal,
  },

  props: {
    plan: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      currentLanguage,
      isPaymentModalOpened: false,
    }
  },

  computed: {
    ...mapGetters('user', ['contractedPlanLevel', 'isBrazil']),
    isContracted() {
      return this.contractedPlanLevel == this.plan.priority_level
    },
    contactWhatsapp() {
      let planText = this.plan.type
      if (this.plan.client)
        planText += ` ${this.$t(`Plans.` + this.plan.client)}`
      return (
        process.env.VUE_APP_COMMERCIAL_WHATSAPP +
        this.$t('contato.msg_whatsapp_hire_plan', [planText])
      )
    },
  },

  methods: {
    closeModal() {
      this.isPaymentModalOpened = false
    },
    formatPrice(price) {
      return this.$currency(price).replace(/\s/g, '')
    },
    formattedText(description) {
      return description.text.replace(
        new RegExp(description.highlight, 'g'),
        `<strong>${description.highlight}</strong>`
      )
    },
    talkSeller() {
      logEvent(events.plansModule.clicked_talk_to_sales)
      if (
        this.isBrazil &&
        (this.plan.priority_level === planLevels.light ||
          this.plan.priority_level === planLevels.pro)
      ) {
        this.isPaymentModalOpened = true
      } else {
        window.open(this.contactWhatsapp).focus()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.plan-card {
  max-width: 310px;
  margin: 0px auto;

  &.en {
    max-width: 320px;
  }

  @media (max-width: 425px) {
    max-width: 425px !important;
    width: 100%;
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 426px) and (max-width: 959px) {
    max-width: 959px !important;
    width: 100%;
    padding: 0px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .card__title {
    display: flex;
    gap: 5px;

    .title__icon {
      height: 29px;
      width: auto;
      margin: auto 0px;
    }
    .title__divider {
      margin: auto 0px auto 0px;
      height: 16px;
    }
    .title__plan-type {
      height: 18px;
      padding: 0px 9px;
      border-radius: 50px;

      p {
        font-family: 'Rubik';
        font-size: 10px;
        font-weight: bold;
        text-transform: capitalize;
        line-height: 18px;
        color: #ffffff;
      }
      &.client {
        height: 20px;

        p {
          font-size: 12px;
          text-transform: uppercase;
        }
      }
      &.light {
        background-color: transparent;

        p {
          color: #788476;
        }
      }
      &.pro {
        background-color: #39af49;
      }
      &.full {
        background: linear-gradient(
          90deg,
          #39af49 0%,
          #2ede46 36.79%,
          #f2cd12 100%
        );
      }
    }
  }
  .card__subtitle {
    max-width: 340px;

    p {
      font-family: 'Rubik';
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #6f6c90;
      text-wrap: wrap;

      @media (max-width: 959px) {
        padding: 0px 36px;
        text-align: center;
      }
    }
  }
  .text__prices {
    height: 78px;

    @media (max-width: 425px) {
      height: auto;
    }

    .text__main-price {
      font-family: 'Rubik';
      font-size: 42px;
      font-weight: 700;
      line-height: 50px;
      color: #170f49;
    }
    .text__secondary-price {
      font-family: 'Rubik';
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      color: #170f49;
    }
    .price-units {
      font-family: 'Rubik';
      font-size: 20px;
      font-weight: 600;
      color: #6f6c90;
      line-height: 25px;
    }
  }
  .plan-card__features {
    max-width: 308px;
    margin-top: 12px;

    @media (max-width: 959px) {
      max-width: 100%;
    }
  }
  .observation {
    margin: 32px auto;
    font-family: 'Rubik';
    font-size: 14px;
    font-weight: 400;
    color: #6f6c90;
    line-height: 19px;
  }
  .plan-card__actions {
    margin-bottom: 12px;

    @media (max-width: 959px) {
      display: flex;
      align-self: flex-start;
    }
  }
}
</style>
