<template>
  <div class="plans-header">
    <img
      alt="farmer"
      class="header__image"
      src="img/backgrounds/img-header-multiple-upsell-1920.webp"
      srcset="
        img/backgrounds/img-header-multiple-upsell-768.webp   768w,
        img/backgrounds/img-header-multiple-upsell-1264.webp 1264w,
        img/backgrounds/img-header-multiple-upsell-1920.webp 1920w
      "
      sizes="
          (max-width: 768px) 768px,
          (max-width: 1264px) 1264px,
          (max-width: 1920px) 1920px,
          1920px
        "
    />
    <h1 class="plans-header__text" v-html="$t('Plans.header_text')"></h1>
  </div>
</template>

<script>
export default {
  name: 'PlansHeader',
}
</script>

<style lang="scss" scoped>
.plans-header {
  max-width: 1440px;
  height: 143px;
  margin: 0px auto;
  position: relative;
  overflow: hidden;

  .header__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  .plans-header__text {
    position: absolute;
    top: calc(50% - 14px);
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Rubik';
    color: #4b4b4b;
    font-size: 34px;
    line-height: 40px;
    font-weight: 500;
    text-align: center;

    @media (max-width: 1264px) {
      width: 100%;
      padding: 0 32px;
      font-size: 26px;
      line-height: 30px;
    }
  }
}

::v-deep .plans-header__text span {
  font-weight: 800;
}
</style>
